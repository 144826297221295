<template>
  <div>
    <!-- <div class="menu-nav">
      <div>
        <a-icon type="setting" class="menu-nav-icon"></a-icon>
        <span class="menu-nav-text">知识数据库</span>
      </div>
      <a-button-group>
        <a-button style="" type="primary" icon="plus-square" v-hasPermi="['knowdata_add']" @click="addKnowledge">新增</a-button>
        <a-button style="margin-left:10px;" type="primary" v-hasPermi="['knowdata_delete']" icon="delete" @click="deleteKnowledge" :disabled="multiple">删除</a-button>
      </a-button-group>
    </div> -->
    <div class="table-search">
      <a-form-model :model="queryParams" ref="queryForm" size="small" layout="inline" label-width="68px">
          <a-form-model-item label="样品编号" prop="name">
              <a-select v-model="queryParams.name" style="width:200px;" placeholder="" :maxTagCount="1" @change="handleChange" allowClear show-search>
                <a-select-option v-for="item in files" :key="item.name" :value="item.name">{{ item.name }}</a-select-option>
              </a-select>
          </a-form-model-item>
          <a-form-model-item label="开始时间" prop="startValue">
            <a-date-picker
              v-model="queryParams.startValue"
              show-time
              valueFormat="YYYY-MM-DD HH:mm:ss"
              allowClear
            />
          </a-form-model-item>
          <a-form-model-item label="结束时间" prop="endValue">
            <a-date-picker
              v-model="queryParams.endValue"
              show-time
              valueFormat="YYYY-MM-DD HH:mm:ss"
              allowClear
            />
          </a-form-model-item>
          <a-form-model-item>
            <a-button icon="search" type="primary"  @click="search">查询</a-button>
            <a-button icon="a-icon-refresh" @click="resetQuery" style="margin-left: 10px">重置</a-button>
          </a-form-model-item>
          <a-form-model-item style="float: right;margin-right: 0px;">
            <a-button style="" type="primary" icon="plus-square" v-hasPermi="['knowdata_add']" @click="addKnowledge">新增</a-button>
            <a-button style="margin-left:10px;" type="primary" v-hasPermi="['knowdata_delete']" icon="delete" @click="deleteKnowledge" :disabled="multiple">删除</a-button>
          </a-form-model-item>
        </a-form-model>
    </div>
    <!--   知识数据库的数据表格-->
    <div class="card" style="padding:6px ;height:calc(100vh - 160px)">
      <a-table rowKey="id" :columns="knowledgeColumns" :dataSource="knowledgeDatas" :row-selection="rowSelectionConfig"
               ref="eleKnowledge" :pagination="{
        defaultCurrent: queryParams.pageNum, // 默认当前页数
        defaultPageSize: queryParams.pageSize, // 默认当前页显示数据的大小
        total: total, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['20', '50', '100'],
        showTotal: (total, range) => `每页${queryParams.pageSize}条，共 ${total} 条`, // 显示总数
        onShowSizeChange: onPageSizeChange,
        // 改变每页数量时更新显示
        onChange: onPageSizeChange,
      }" bordered>
        <template v-slot:action="record">
          <a href="javascript:;" v-hasPermi="['knowdata_view']" @click="handleViewKnowledge(record)"><a-icon type="eye" /> 查看</a>
          <a href="javascript:;" v-hasPermi="['knowdata_edit']" @click="handleEditKnowledge(record)"><a-icon type="form" /> 编辑</a>
          <a href="javascript:;" v-hasPermi="['knowdata_delete']" @click="handleDeleteKnowledge(record.id)"><a-icon type="delete" /> 删除</a>
        </template>
      </a-table>
    </div>
    <know-data-dialog :show-modal="showKnow" :knowledge-data="knowledgeData" :dialog-type="dialogType" @onFresh="onFresh"/>
  </div>
</template>

<script lang="ts">
import {getFilesData, getKnowledgeDataList,deleteKnowledgeData} from "../api/knowledge/database.js";
import { Modal, message } from 'ant-design-vue';
import KnowDataDialog from "./KnowDataDialog";

export default {
  components: {
    KnowDataDialog
  },
  data() {
    return {
      knowledgeColumns: [
        {
          title: '样品编号',
          dataIndex: 'name',
        },
        {
          title: '图片数量',
          dataIndex: 'imageTotal',
        },
        {
          title: '赋值状态',
          dataIndex: 'verified',
        },
        {
          title: '入库日期',
          dataIndex: 'createTime',
        },
        {
          title: '入库人员',
          dataIndex: 'createBy',
        },
        {
          title: '操作',
          key: 'operation',
          width: '180px',
          align:'center',
          className: 'operation-cls',
          scopedSlots: {customRender: 'action'}
        }
      ],
      showKnow: false,
      knowledgeData: {},
      knowledgeDatas: [],
      selectedKnowledgeIds: [],
      dialogType:"add",
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      startValue: null,
      endValue: null,
      endOpen: false,
      files: [],
      fileValue: null,
      multiple: true,
    };
  },
  async created() {
    this.getFilesData();
    this.search();
  },
  computed: {
    rowSelectionConfig() {
      return {
        selectedRowKeys: this.selectedKnowledgeIds,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedKnowledgeIds = selectedRowKeys;
          this.multiple = !this.selectedKnowledgeIds.length
        }
      }
    }
  },
  methods: {
    getFilesData() {
      getFilesData({}).then(res => {
        if(res.success) {
          this.files = res.data;
        }
      });
    },
    async handleViewKnowledge(row) {
      // let data=await detailKnowledgeData(id);
      this.knowledgeData = { ...row };
      this.dialogType="view";
      this.showKnow = true;
    },
    async handleEditKnowledge(row) {
      // let data=await detailKnowledgeData(id);
      this.knowledgeData = { ...row };
      this.dialogType="edit";
      this.showKnow = true;
    },
    handleDeleteKnowledge(id) {
      Modal.confirm({
        title:"提示信息",
        content:"确认要删除选中的知识数据吗？",
        centered:true,
        onOk: async()=>{
          let res =await deleteKnowledgeData(id);
          if(res.success){
              message.success("操作成功");
              this.selectedKnowledgeIds = [];
              this.multiple = true
              this.search();
          }
        }
      });
    },
    addKnowledge() {
      this.dialogType="add";
      this.showKnow = true;
      this.knowledgeData = {};
    },
    deleteKnowledge() {
      if(this.selectedKnowledgeIds&&this.selectedKnowledgeIds.length>0) {
        let ids=this.selectedKnowledgeIds.join();
        this.handleDeleteKnowledge(ids);
      }else{
        Modal.info({
          title:"提示信息",
          content:"必须选择要删除的数据行！",
          centered:true,
        });
      }
    },
    closeKnowledge(){//子组件调用
      this.showKnow=false;
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.$refs["queryForm"].resetFields();
      this.search();
    },
    async search() {
      let res = await getKnowledgeDataList({
        ...this.queryParams,
      });
      if(res.success){
        this.knowledgeDatas =res.rows;
        this.total=res.total;
      }else{
        Modal.error({
          title:"错误信息",
          content:res.msg,
          centered:true,
        });
      }
    },
    onFresh() {
      this.search();
      this.getFilesData();
    },
    disabledStartDate(startValue) {
      const endValue = this.endValue;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.startValue;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true;
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open;
    },
    handleChange(value) {
      this.fileValue = value;
    },
    onPageSizeChange(current, pageSize){
      this.queryParams = {
        ...this.queryParams,
        pageNum: current,
        pageSize,
      };
      this.search();
    },
  }
}

</script>

<style scoped>
.operation-cls {
  padding: 0px;
  width: 10px;
}

.operation-cls a {
  margin: 0px 2px;
}
</style>
